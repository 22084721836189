import React from "react"
import Helmet from 'react-helmet'
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Logo from 'src/elements/Logo'

import Layout from 'src/components/layout'
import Banner from 'src/components/Banners/SubBanner'

import "./thanx.scss"
import ScreensImg from "src/images/how-we-help/thanx/thanx-screens.png"
import AdminAreaImg from "src/images/how-we-help/thanx/thanx-customise.png"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark

  const ThanxLogo = () => {
    const logoPath = require("src/images/clients/thanx.png");
    const insert = (logoPath) => {
        return <img src={logoPath} alt={`Thanx logo`} title="Thanx" />
    }
    return <Logo key="thanx" link="https://www.thanx.com" name={insert(logoPath)} logo="none" />
  }

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
      </Helmet>
      <Banner title={frontmatter.headline} eyebrow={{link:'/how-we-help/',text:'How we help'}} />
      <section id="intro" className="off">
        <div className="wrap">
          <div className="content">
            <ThanxLogo />
            <p>Thanx provides businesses with an automated customer engagement platform, bridging the gap for offline "bricks and mortar" business by creating a single system of record for customer data and automating the way to engage with customers - ultimately driving revenue.</p>
            <p>The Thanx marketing team are a talent when it comes to content writing and go-to-market strategies, however they lack the creative arm of the team. Using in-house product designers (when available) or contracting designers from a list they have used for previous campaigns, they have enlisted ARD Web Development to look after they web asset from a development prospective on an ongoing basis.</p>
            <p>This allows us to get familiar with the Thanx environment - know it inside out, learn how the client works, and turn out work faster than in the beginning from knowing their system more intimately.</p>
          </div>
        </div>
      </section>
      <section id="campaigns" className="detail left">
        <div className="wrap">
            <div className="image">
              <img src={ScreensImg} alt="Customised landing pages and templates" title="Thanx customised campaigns" />
            </div>
            <div className="content">
            <h2>Create better campaigns through customisation.</h2>
            <p>There are thousands of themes and stock templates around that allow you to quickly spin up a page or site to get you off the ground. There are also thousands of other people doing the same thing. What sets Thanx apart from the rest? Customised user experience and visual presence.</p>
            <p>Being able to produce a unique campaign, exactly how the marketers need it to work and function and exactly how the designers envisage it to be, makes a Thanx campaign page or section stand out from the rest. Something new and fresh that reader hasn't seen before is immediately eyecatching.</p>
            <p>ARD Web Development work closely with the Thanx team to produce new sections and campaign landing pages to improve their existing asset and empower the team to market their product efficiently.</p>
            <p>Projects like the homepage revamp, benefits section, resources hub and various other first touch landing pages have all been completed in the on-going partnership we have (and are) building together.</p>
            </div>
        </div>
        </section>
        <section id="adminarea" className="detail right">
        <div className="wrap">
            <div className="image">
            <img src={AdminAreaImg} alt="System management and maintenance" title="Thanx managed admin area" />
            </div>
            <div className="content">
            <h2>Asset management can be a tricky task...</h2>
            <p>ARD Web Development handle that for Thanx so they can concentrate on what they do best. The Thanx web asset uses a very popular content management system that, to stay secure and run as optimally as it can, requries reqular updates to both the core system and plugins used.</p>
            <p>We also believe in empowering the user. In this case, this means providing any possibility to open the ability for the Thanx marketing team to control and update content and various settings so that they don't need to call on us for simple updates or changes - which can tend to eat up budgets.</p>
            <p>Outside of the website admin itself, we also help Thanx with their Google Tag Manager, their AB testing campaigns and working with their IT team to monitor their web servers.</p>
            <Link className="btn" to="/contact-us/">Get in contact</Link>
            </div>
        </div>
        </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
        html
        frontmatter {
            path
            title
            headline
        }
    }
  }
`